export default {
  "popregi": "Popręgi",
  "siodla-ujezdzeniowe": "Siodła ujeżdżeniowe",
  "siodla-skokowe": "Siodła skokowe",
  "popregi": "Popręgi",
  "pusliska": "Puśliska",
  "strzemiona": "Strzemiona",
  "podkladki": "Podkładki",
  "czapraki": "Czapraki",
  "pokrowce": "Pokrowce",
  "pielegnacja": "Pielęgnacja",
  "pasowanie-siodel": "Pasowanie siodeł",
  "fitandride": "Fit&Ride",
}