import React from 'react';
import Typography from '@material-ui/core/Typography';
import MuiTimeline from '@material-ui/lab/Timeline';
import MuiTimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';


import { graphql, Link } from "gatsby"
import { makeStyles, withStyles } from "@material-ui/core/styles"

const TimelineItem = withStyles(theme => ({
  missingOppositeContent: {
    "&:before": {
      flex: 0
    }
  }
}))(MuiTimelineItem)

const useStyles = makeStyles(theme => ({
  content: {
    marginBottom: theme.spacing(8)
  },
  timelineDot: {
    backgroundColor: theme.palette.error.main,
  },
  timelineConnector: {
    backgroundColor: theme.palette.error.main,
  }
}))

const TestsTimeline = ({ details }) => {
  const classes = useStyles()

  // if (!details || !details.length) return null

  return (
    <MuiTimeline>
      {details.map(({ id, timelineTitle, timelineDescription }, index) => (
        <TimelineItem key={id}>
          <TimelineSeparator>
            <TimelineDot className={classes.timelineDot} />
            {index < details.length - 1 && <TimelineConnector className={classes.timelineConnector} />}
          </TimelineSeparator>
          <TimelineContent className={classes.content}>
            <Typography gutterBottom>
              <strong>{timelineTitle}</strong>
            </Typography>
            <Typography variant="body2">{timelineDescription}</Typography>
          </TimelineContent>
        </TimelineItem>
      ))}
    </MuiTimeline>
  );
}

export default TestsTimeline