import React from "react"
import cx from 'classnames'
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
// import MuiBreadcrumbs from "@material-ui/core/Breadcrumbs"
// import EventNoteIcon from "@material-ui/icons/EventNote"

import { graphql, Link } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Seo from "../../components/seo"
import Title from "../../components/title"
import Hero from "../../components/hero"
import Button from "../../components/button"
import Layout from "../../components/layout"
import constant from "../../helpers/constant"
import Timeline from "../../components/timeline"

const useStyles = makeStyles(theme => ({
	timeline: {
		backgroundColor: theme.palette.common.white,
		paddingTop: theme.spacing(10),
		paddingBottom: theme.spacing(10),
		paddingLeft: theme.spacing(5),
		[theme.breakpoints.down('sm')]: {
			paddingLeft: "initial",
		}
	},

	heroTitle: {
		'& section > h1': {
			[theme.breakpoints.down('lg')]: {
				fontSize: '150px',
			},
			'@media(max-width: 687px)': {
				fontSize: '120px ',
			},
			[theme.breakpoints.down('xs')]: {
				fontSize: '90px ',
			},
		},
	},

	grid: {
		paddingTop: theme.spacing(10),
		paddingBottom: theme.spacing(10),
		[theme.breakpoints.down('sm')]: {
			paddingTop: "initial",
			paddingBottom: theme.spacing(0),
		}
	},
	image: {
		top: 40,
		width: `calc(100% + ${theme.spacing(2.5)})`,
		[theme.breakpoints.down('sm')]: {
			top: "initial",
			width: "initial",
		}
	},
	gridTitle: {
		[theme.breakpoints.down('sm')]: {
			marginBottom: theme.spacing(3),
			fontSize: '2rem',
		}
	}
}))

const TestsPage = ({ data }) => {
	const classes = useStyles()

	const {
		heroImage,
		heroTitle,
		pageTestsDescription,
		pageTestsTimelineImage,
		pageTestsTimelineTitle,
		pageTestsTitle,
		timelineDetails,
		seo,
		slug,
		timelineSlogan
	} = (data && data.datoCmsPageTest) || {}


	return (
		<Layout>
			{seo && <Seo title={seo.title} description={seo.description} />}
			<Box className={classes.heroTitle}><Hero full title={heroTitle} image={heroImage} /></Box>
			<Container maxWidth="lg">
				<Box mt={8}>
					<Title className={classes.gridTitle}>{pageTestsTitle}</Title>
					<Typography component="div" dangerouslySetInnerHTML={{ __html: pageTestsDescription }} />
				</Box>
				<Grid container className={classes.grid}>
					<Grid item sm={6} xs={12} className={classes.item}>
						<Title className={classes.gridTitle}>{pageTestsTimelineTitle}</Title>
						<GatsbyImage image={getImage(pageTestsTimelineImage)} alt={pageTestsTitle} className={classes.image} />
					</Grid>
					<Grid item sm={6} xs={12} className={cx(classes.item, classes.timeline)}>
						<Timeline details={timelineDetails} />
					</Grid>
				</Grid>
				<Box display="flex" justifyContent="center" my={6}>
					<Link to="/kontakt">
						<Button bgColor="#000" size="medium">Kontakt</Button>
					</Link>
				</Box>
			</Container>
		</Layout>
	)
}

export const query = graphql`
  query queryTests {
    datoCmsPageTest {
      heroTitle
      heroImage {
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
      }
      pageTestsDescription
      pageTestsTimelineImage {
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
      }
      pageTestsTimelineTitle
      timelineSlogan
      timelineDetails {
        id
        timelineTitle
        timelineDescription
      }
      pageTestsTitle
      slug
      seo {
        title
        description
      }
    }
  }
`

export default TestsPage
